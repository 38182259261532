import { Icon, IconProps } from "@chakra-ui/react";

export function Icons8(props: IconProps) {
  return (
    <Icon width={110} height={34} viewBox="0 0 110 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.317.35H0v33.47h16.317V.35z" fill="#1FB141" />
      <path
        d="M24.475 17.084a8.056 8.056 0 005.769-2.45 8.478 8.478 0 002.39-5.918c0-2.219-.86-4.347-2.39-5.916A8.056 8.056 0 0024.475.349a8.056 8.056 0 00-5.769 2.45 8.478 8.478 0 00-2.39 5.917c0 2.22.86 4.348 2.39 5.917a8.056 8.056 0 005.77 2.45zm0 16.735a8.056 8.056 0 005.769-2.45 8.478 8.478 0 002.39-5.918c0-2.219-.86-4.347-2.39-5.916a8.056 8.056 0 00-5.769-2.451 8.056 8.056 0 00-5.769 2.45 8.477 8.477 0 00-2.39 5.917c0 2.22.86 4.348 2.39 5.917a8.056 8.056 0 005.77 2.451z"
        fill="#1FB141"
      />
      <path
        d="M43.081 24.056h2.894v-13.55h-2.894v13.55zM54.935 24.252c1.256 0 2.436-.235 3.217-.567v-2.538c-1.01.371-1.999.527-3.084.527-2.341 0-3.389-.878-3.389-4.412 0-3.32 1.047-4.413 3.427-4.413 1.104 0 2.075.176 2.893.469V10.76c-.8-.293-1.827-.449-3.083-.449-3.884 0-6.377 1.738-6.377 6.97 0 5.662 2.531 6.97 6.396 6.97zM66.005 24.252c3.864 0 5.996-1.445 5.996-6.97 0-5.526-2.132-6.97-5.996-6.97-3.826 0-5.996 1.444-5.996 6.97 0 5.525 2.17 6.97 5.996 6.97zm0-2.48c-2.418 0-2.95-1.171-2.95-4.49 0-3.3.532-4.55 2.95-4.55 2.437 0 2.97 1.25 2.97 4.55 0 3.319-.533 4.49-2.97 4.49zM74.591 24.056h2.856V13.747h.114l4.569 10.31h3.978v-13.55h-2.836v9.684h-.114l-4.15-9.684h-4.416v13.55zM92.462 24.252c3.636 0 5.083-1.426 5.083-4.081 0-2.538-.685-3.514-3.845-4.315-1.942-.488-2.246-.761-2.246-1.66 0-1.132.647-1.444 2.132-1.444 1.104 0 2.627.254 3.274.488V10.8c-.705-.254-1.904-.489-3.389-.489-3.065 0-4.835 1.094-4.835 3.964 0 2.42.819 3.221 3.636 3.905 2.018.507 2.399.878 2.399 1.913 0 1.21-.572 1.62-2.323 1.62-1.351 0-2.893-.253-3.73-.546v2.596c.799.235 2.303.489 3.844.489zM104.191 24.271c3.046 0 4.816-1.23 4.816-3.768 0-2.109-1.066-2.987-2.303-3.417v-.078c1.199-.547 1.903-1.386 1.903-3.065 0-2.324-1.522-3.651-4.416-3.651-2.912 0-4.435 1.327-4.435 3.65 0 1.738.742 2.539 1.77 3.066v.078c-1.047.43-2.17 1.289-2.17 3.397 0 2.558 1.789 3.788 4.835 3.788zm0-8.24c-1.447 0-1.999-.76-1.999-1.834 0-1.191.705-1.738 1.999-1.738 1.275 0 1.98.605 1.98 1.738 0 1.073-.571 1.835-1.98 1.835zm0 5.995c-1.561 0-2.284-.625-2.284-1.796 0-1.387.837-2.07 2.284-2.07 1.428 0 2.265.683 2.265 2.07 0 1.171-.742 1.796-2.265 1.796z"
        fill="#000"
      />
    </Icon>
  );
}
