import { Icon, IconProps } from "@chakra-ui/react";

export function Feather(props: IconProps) {
  return (
    <Icon width={128} height={40} viewBox="0 0 128 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M33.042 20.494a10.184 10.184 0 002.87-7.108c0-2.666-1.032-5.222-2.87-7.107a9.678 9.678 0 00-6.93-2.945 9.678 9.678 0 00-6.93 2.945L8.162 17.58v14.232H22.04l11.003-11.32zM26.12 13.395L3.265 36.836M28.569 25.116H14.692"
        stroke="#000"
        strokeWidth={3.483}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.628 27.897h3.253v-6.544h6.259v-2.761H52.88v-3.775h6.935v-2.761H49.628v15.841zM66.356 28.13c2.855 0 4.778-1.432 5.23-3.636l-2.961-.201c-.323.905-1.15 1.377-2.216 1.377-1.6 0-2.615-1.091-2.615-2.863V22.8h7.859v-.905c0-4.038-2.374-6.034-5.425-6.034-3.396 0-5.597 2.483-5.597 6.15 0 3.767 2.171 6.118 5.725 6.118zm-2.562-7.372c.068-1.354 1.067-2.437 2.487-2.437 1.39 0 2.351 1.021 2.359 2.437h-4.846zM77.032 28.122c1.706 0 2.81-.766 3.374-1.872h.09v1.647h3.035v-8.013c0-2.832-2.329-4.023-4.898-4.023-2.765 0-4.584 1.362-5.027 3.528l2.96.247c.218-.789.902-1.37 2.052-1.37 1.089 0 1.713.566 1.713 1.54v.047c0 .765-.79.866-2.795 1.067-2.284.217-4.335 1.006-4.335 3.659 0 2.351 1.63 3.543 3.831 3.543zm.917-2.274c-.984 0-1.69-.472-1.69-1.377 0-.929.743-1.385 1.87-1.547.699-.101 1.84-.271 2.224-.534v1.26c0 1.246-1 2.198-2.404 2.198zM92.153 16.016H89.98v-2.847h-3.2v2.847h-1.578v2.475h1.578v6.189c-.015 2.328 1.525 3.48 3.846 3.38a6.068 6.068 0 001.736-.31l-.503-2.452c-.158.031-.496.109-.797.109-.639 0-1.082-.248-1.082-1.16V18.49h2.172v-2.475zM97.686 21.028c.007-1.531.917-2.428 2.224-2.428 1.3 0 2.066.858 2.059 2.32v6.977h3.2v-7.565c.008-2.784-1.585-4.47-3.989-4.47-1.75 0-2.923.85-3.449 2.25h-.135v-6.056h-3.11v15.841h3.2v-6.869zM112.947 28.13c2.855 0 4.779-1.432 5.229-3.636l-2.96-.201c-.323.905-1.149 1.377-2.216 1.377-1.601 0-2.615-1.091-2.615-2.863V22.8h7.859v-.905c0-4.038-2.374-6.034-5.425-6.034-3.395 0-5.597 2.483-5.597 6.15 0 3.767 2.171 6.118 5.725 6.118zm-2.562-7.372c.068-1.354 1.067-2.437 2.487-2.437 1.39 0 2.352 1.021 2.359 2.437h-4.846zM120.333 27.897h3.2v-6.722c0-1.462 1.037-2.467 2.45-2.467.443 0 1.051.077 1.352.178v-2.924a4.68 4.68 0 00-1.007-.116c-1.292 0-2.351.773-2.772 2.243h-.12v-2.073h-3.103v11.881z"
        fill="#000"
      />
    </Icon>
  );
}
